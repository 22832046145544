
import './App.css';
import Main from './Main';
import "../src/css/Style.css"
import "../src/css/Responsive.css"

function App() {
  return (
    <div>
      <Main/>
    </div>
  );
}

export default App;
