import React, { useState } from "react";
import CustomModal from "../../common/CustomModal";

const SpeakersLineup = () => {
  const [activeCard, setActiveCard] = useState(1);
  const [openModal, setOpenModal] = useState(false);
  const [currentMemberId, setCurrentMemberId] = useState("");
  const [currentItem, setCurrentItem] = useState({});

  const coreCommittee = [
    {
      id: 1,
      img: require("../../assets/images/speakers/s1.webp"),
      name: "C Sivasankaran",
      pos: "Founder, Aircel & <br/> Mentor, AIWO HEALTH",
      para: "Telecom titan who reshaped India’s mobile revolution from scratch. <br/><br/> Built Aircel into a nationwide giant, bringing connectivity to millions and disrupting an entire industry.",
      insta: "https://www.instagram.com/chinnakannansivasankaran/",
      facebook: "",
      linkedin: "",
    },
    {
      id: 2,
      img: require("../../assets/images/speakers/s2.webp"),
      name: "Dr.A.Velumani,PhD",
      pos: "Creator, Thyrocare",
      para: " From ₹500 to a billion-dollar brand—he transformed India's diagnostics landscape.<br/><br/> His bold bet on low-cost, high-quality testing made preventive healthcare accessible for all.",
      insta: "https://www.instagram.com/drvelumani/?hl=en",
      facebook: "https://www.facebook.com/velumania1/",
      linkedin: "",
    },
    {
      id: 3,
      img: require("../../assets/images/speakers/s3.webp"),
      name: "C K Kumaravel",
      pos: "Co-Founder, Naturals",
      para: "Architect of India’s salon revolution with 700+ outlets and a mission to create 1,000+ women entrepreneurs.<br/><br/>His vision? A “housewife-free” India powered by empowered women.",
      insta: "https://www.instagram.com/ckkumaravel/?hl=en",
      facebook: "https://www.facebook.com/kumaravel.ck",
      linkedin: "https://www.linkedin.com/in/ckknaturals/",
    },
    {
      id: 4,
      img: require("../../assets/images/speakers/s4.webp"),
      name: "Latha Pandiyarajan",
      pos: "Co-Founder & Director,<br/> Ma Foi-CIAL Group",
      para: "HR pioneer and social transformer.<br/><br/>From founding India’s first HR brand to empowering 60,000+ women, her impact is deep, lasting, and legendary.",
      insta: "",
      facebook: "https://www.facebook.com/latharajan07/",
      linkedin:
        "https://in.linkedin.com/in/latha-rajan-3520b150?original_referer=https%3A%2F%2Fwww.google.com%2F",
    },
    {
      id: 5,
      img: require("../../assets/images/speakers/s5.webp"),
      name: "Surendran Jayasekar",
      pos: "Founder, Success Gyan",
      para: "Built India’s No.1 personal development platform—Success Gyan—and scaled the world’s largest digital freedom community.<br/><br/>Fueling India’s transformation through education, mindset, and massive action.",
      insta: "https://www.instagram.com/successsuren/?hl=en",
      facebook: "https://www.facebook.com/surendran.jayasekar/",
      linkedin: "https://in.linkedin.com/in/successsuren",
    },
    {
      id: 6,
      img: require("../../assets/images/speakers/s6.webp"),
      name: "Akhila Rajeshwar",
      pos: "Executive Director,<br/> TiE Chennai",
      para: "The ecosystem enabler behind 2,000+ startups and India’s most vibrant founder community.<br/><br/>A relentless force building Chennai’s startup dreams into reality.",
      insta: "",
      facebook: "",
      linkedin: "https://in.linkedin.com/in/akhila-rajeshwar-46aa2017",
    },
    {
      id: 7,
      img: require("../../assets/images/speakers/s7.webp"),
      name: "Siddharth Rajsekar",
      pos: "Founder, Internet Lifestyle Hub India's Digital Reformer & Mentor",
      para: "Creator of the Internet Lifestyle Hub—a 25,000+ strong digital army.<br/><br/>He’s redefining how India learns, earns, and lives—one digital coach at a time.",
      insta: "https://www.instagram.com/siddharthrajsekar/",
      facebook: "https://www.facebook.com/siddharthrajsekar/",
      linkedin: "https://in.linkedin.com/in/siddharthrajsekar",
    },
    {
      id: 8,
      img: require("../../assets/images/speakers/s8.webp"),
      name: "Deepak Kanakaraju aka Digital Deepak",
      pos: "Co-Founder, Pixeltrack",
      para: "India’s most trusted digital marketing mentor—Digital Deepak has trained over 60,000 professionals.<br/><br/>From engineer to educator, he’s built a digital movement that’s changing lives.",
      insta: "https://www.instagram.com/deepak.kanakaraju/",
      facebook: "https://www.facebook.com/deepak.biz/",
      linkedin: "https://in.linkedin.com/in/deepakkanakaraju",
    },
    {
      id: 9,
      img: require("../../assets/images/speakers/s9.webp"),
      name: "Gopal Krishnan",
      pos: "Creator, Binge Marketing",
      para: "The mind behind “Binge Marketing”—India’s most addictive growth formula.<br/><br/>Called the 'Unicorn Maker' for helping creators scale like never before.",
      insta:
        "https://www.instagram.com/unicorncoachgopal/reels/?locale=en_AE%202",
      facebook: "https://www.facebook.com/GopalKrishnanMarketer/",
      linkedin:
        "https://www.linkedin.com/in/unicorncoach-gopal-krishnan-003a008/",
    },
    {
      id: 10,
      img: require("../../assets/images/speakers/s10.webp"),
      name: "Himanshu Agrawal",
      pos: "Founder, Origin8 Former CEO, Internet Coaching Empire",
      para: "Growth architect behind 100+ internet coaching businesses.<br/><br/>His frameworks helped thousands turn knowledge into consistent high-ticket income.",
      insta: "https://www.instagram.com/himanshuagrawal.official/?hl=en",
      facebook: "https://www.facebook.com/himanshuagrawal1234567/",
      linkedin: "https://in.linkedin.com/in/himanshuagrawalofficial",
    },
    {
      id: 11,
      img: require("../../assets/images/speakers/s11.webp"),
      name: "Dr.Kannan Gireesh",
      pos: "Founder & CEO, <br/>Live Life Education & Pvt. Ltd.",
      para: "Psychiatrist turned education warrior—he’s pioneered emotional learning in India’s schools.<br/><br/>On a mission to shape resilient, emotionally intelligent future leaders.",
      insta: "https://www.instagram.com/kannangireeshpsychiatrist/?hl=en",
      facebook: "https://www.facebook.com/KannanGireeshPsychiatrist/",
      linkedin: "https://in.linkedin.com/in/dr-kannan-gireesh-7ba74415",
    },
    {
      id: 12,
      img: require("../../assets/images/speakers/s12.webp"),
      name: "Surekaa Sundar",
      pos: "CEO, Talent Factory",
      para: "Brand whisperer to over 600+ companies.<br/><br/>Transforms raw potential into powerful brands through mindset, structure, and storytelling mastery.",
      insta: "https://www.instagram.com/surekaa_sundar/?locale=fr_FR&hl=en",
      facebook:
        "https://www.facebook.com/surekaasundar/?profile_tab_item_selected=about&_rdr",
      linkedin: "https://in.linkedin.com/in/surekaa-sundar-626a3215",
    },
    {
      id: 13,
      img: require("../../assets/images/speakers/s13.webp"),
      name: "Dr.Deepak Renganathan",
      pos: "Global AI & Marketing Thought Leader",
      para: "AI expert + Marketing strategist = Gamechanger.<br/><br/>Guiding global brands to blend human creativity with artificial intelligence for exponential growth.",
      insta: "https://www.instagram.com/deep.e.r/",
      facebook: "https://www.facebook.com/deepak.renganathan/",
      linkedin: "https://ae.linkedin.com/in/deepak-renganathan",
    },
    {
      id: 14,
      img: require("../../assets/images/speakers/s14.webp"),
      name: "Suresh Radhakrishnan",
      pos: "Founder & CEO, Mypreneur",
      para: "Street-smart entrepreneur turned mentor to the masses.<br/><br/>Built India’s largest foodpreneur community—empowering 1M+ entrepreneurs with tools to start, grow, and thrive.",
      insta: "https://www.instagram.com/sureshradhakrishnansrk/?hl=en",
      facebook: "https://www.facebook.com/sureshchaikanth/",
      linkedin: "https://in.linkedin.com/in/sureshradhakrishnansrk",
    },
    {
      id: 15,
      img: require("../../assets/images/speakers/s15.webp"),
      name: "Sakthivel Pannerselvam",
      pos: "Guerrilla Marketing Expert",
      para: "The brain behind India’s boldest street-smart brand campaigns.<br/><br/>Turns small budgets into big buzz with guerrilla strategies that disrupt, delight, and dominate.",
      insta: "https://www.instagram.com/sakthivelpannerselvam6/?hl=en",
      facebook: "https://www.facebook.com/sakthivel.pannerselvam.surprise/",
      linkedin: "https://in.linkedin.com/in/sakthivelpannerselvam",
    },
    {
      id: 16,
      img: require("../../assets/images/speakers/s16.webp"),
      name: "Archana Stalin",
      pos: "Founder & Growth Champion,<br/> myHarvest Farms",
      para: "Built a farm-to-home revolution that connects 100+ organic farmers with conscious consumers.<br/><br/>She’s transforming agriculture, nutrition, and sustainability—all in one bold move.",
      insta: "https://www.instagram.com/archanastalin/?hl=en",
      facebook: "https://www.facebook.com/archana.p.stalin/",
      linkedin: "https://in.linkedin.com/in/archanastalin",
    },
    {
      id: 17,
      img: require("../../assets/images/speakers/s17.webp"),
      name: "Nithya",
      pos: "Founder, Habbada Fashions",
      para: "Designer. Innovator. Disruptor of the saree game.<br/><br/>Her brand brings modern ease to ethnic grace, loved by 1 lakh+ women who want comfort and style.",
      insta: "https://www.instagram.com/mindful_nithya/",
      facebook: "https://www.facebook.com/nithya.mani.100/",
      linkedin: "https://in.linkedin.com/in/nithya-g-078a3b22",
    },
    {
      id: 18,
      img: require("../../assets/images/speakers/s18.webp"),
      name: "Epaphra",
      pos: "Founder & CEO, Townscholar",
      para: "Educator innovator future-builder Founder & CEO of Townscholar, empowering students with smarter, tech-driven learning solutions, making quality education accessible and impactful",
      insta: "https://www.instagram.com/explorewithepaphra/reels/?hl=en",
      facebook: "",
      linkedin: "https://www.linkedin.com/in/explorewithepaphra/",
    },
    {
      id: 19,
      img: require("../../assets/images/speakers/s19.webp"),
      name: "Kennet Alphy",
      pos: "Co-Founder, The Goat Media",
      para: "Marketer trendsetter growth hacker Co-founder of The GOAT Media, crafting viral campaigns that turn brands into icons, fueling digital success with creativity and strategy",
      insta: "https://www.instagram.com/kennet_alphy/?hl=en",
      facebook: "https://www.facebook.com/kennetinc/",
      linkedin: "https://www.linkedin.com/in/kennet-alphy-c/",
    },
    {
      id: 20,
      img: require("../../assets/images/speakers/s20.webp"),
      name: "Premkumar C",
      pos: "Founder, Middle Class Editing",
      para: "Editor storyteller visual game-changer Founder of Middle Class Editing, shaping raw footage into compelling narratives, bringing stories to life one seamless cut at a time",
      insta: "https://www.instagram.com/prem_landes?igsh=dXIwNGViNnh1Nzlm",
      facebook: "https://www.facebook.com/premlandes?mibextid=rS40aB7S9Ucbxw6v",
      linkedin: "https://ae.linkedin.com/in/ca-premkumar",
    },
  ];

  return (
    <div className="speakers-line-up-section">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-12">
            <div className="row  justify-content-center ">
              {coreCommittee.map((item, index) => {
                return (
                  <div
                    className="col-lg-3 col-md-6"
                    style={{ cursor: "pointer" }}
                    key={index + 1}
                    onClick={() => {
                      setOpenModal(true);
                      setCurrentMemberId(item.id);
                      setCurrentItem(item);
                    }}
                  >
                    <div className="core-com-card">
                      <div>
                        <img
                          className="w-100 h-auto img-top-team"
                          src={item?.img}
                          alt=""
                        />
                      </div>
                      <div className="core-com-inner-card">
                        <h6 className="core-card-text h-speakers-lineup-heading">
                          {item?.name}
                        </h6>
                        <div className="core-card-subtext-head">
                          <p
                            className="core-card-subtext h-speakers-lineup"
                            dangerouslySetInnerHTML={{ __html: item?.pos }}
                          ></p>
                        </div>

                        <div className="core-com-sm-card h-speakers-lineup">
                          <div className="core-com-sm">
                            {item?.insta?.length > 0 && (
                              <a
                                className="core-comi-redirect"
                                href={item?.insta}
                                target="_blank"
                              >
                                <i class="fa-brands fs-6 fa-instagram"></i>
                              </a>
                            )}
                            {item?.facebook?.length > 0 && (
                              <a
                                className="core-comi-redirect"
                                href={item?.facebook}
                                target="_blank"
                              >
                                <i class="fa-brands fs-6 fa-facebook"></i>
                              </a>
                            )}
                            {item?.linkedin?.length > 0 && (
                              <a
                                className="core-comi-redirect"
                                href={item?.linkedin}
                                target="_blank"
                              >
                                <i class="fa-brands fs-6 fa-linkedin-in"></i>
                              </a>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <CustomModal
        open={openModal}
        onClickOutside={() => {
          setOpenModal(false);
          setCurrentMemberId(null);
        }}
      >
        <div className="modal-content contact-container m-auto">
          <div className="modal-body w-100 p-0 ">
            <div className="container">
              <div className="row row-reverse-mob">
                <div className="col-lg-7 col-md-8">
                  <div className="modal-bg-speaker-card border-top-radius">
                    <div className="d-flex justify-content-start gap-3 mb-2">
                      {currentItem.insta && (
                        <div>
                          <a
                            className="our-team-sm-redirect"
                            href={currentItem.insta}
                            target="_blank"
                          >
                            <i class="fa-brands fs-5 fa-instagram"></i>
                          </a>
                        </div>
                      )}
                      {currentItem.facebook && (
                        <div>
                          <a
                            className="our-team-sm-redirect"
                            href={currentItem.facebook}
                            target="_blank"
                          >
                            <i class="fa-brands fs-5 fa-facebook"></i>
                          </a>
                        </div>
                      )}
                      {currentItem.linkedin && (
                        <div>
                          <a
                            className="our-team-sm-redirect"
                            href={currentItem.linkedin}
                            target="_blank"
                          >
                            <i class="fa-brands fs-5 fa-linkedin-in"></i>
                          </a>
                        </div>
                      )}
                    </div>
                    <p
                      className="speakers-modal-para"
                      dangerouslySetInnerHTML={{ __html: currentItem.para }}
                    ></p>
                  </div>
                </div>
                <div className="col-lg-5 col-md-4">
                  <div className="">
                    <img
                      className="team-open-card-modal img-top-team"
                      src={currentItem.img}
                      alt={currentItem.name}
                    />
                    <div className="modal-bg-speaker-card border-bottom-radius">
                      <h6 className="core-card-text text-dark ">
                        {currentItem?.name}
                      </h6>
                      <p
                        className="core-card-subtext h-auto text-dark text-small"
                        dangerouslySetInnerHTML={{ __html: currentItem?.pos }}
                      ></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </CustomModal>
    </div>
  );
};

export default SpeakersLineup;
