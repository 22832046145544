import React from "react";
import { HashRouter, Route, Router, Routes } from "react-router-dom";
import Ticket from "./Pages/Ticket";
import Home from "./Pages/Home";
import PartnerWithUs from "./Pages/PartnerWithUs";
import Awards from "./Pages/Awards";
import Agenda from "./Pages/Agenda";
import Gallery from "./Pages/Gallery";

const Main = () => {
  return (
    <>
      <HashRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/Ticket" element={<Ticket />} />
          <Route path="/PartnerWithUs" element={<PartnerWithUs />} />
          <Route path="/Awards" element={<Awards />} />
          <Route path="/Agenda" element={<Agenda />} />
          <Route path="/TNDS2024Highlights" element={<Gallery />} />
        </Routes>
      </HashRouter>
    </>
  );
};

export default Main;
