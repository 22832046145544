import React, { useState } from "react";
import { useInView } from "react-intersection-observer";
import CountUp from "react-countup";
import DesignImg from "../../assets/images/design-img.webp";
import { useNavigate } from "react-router-dom";
import TicketWhite from "../../assets/images/ticket-white.webp";
import TicketBlack from "../../assets/images/ticket-black.webp";
import SpeakersLineup from "./SpeakersLineup";
import OwlCarousel from "react-owl-carousel3";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import HorizontalScrollSection from "./HorizontalScrollSection";
const HomeContent = () => {
  const navigate = useNavigate();

  const options = {
    threshold: 0.5,
  };
  const [ref1, inView1] = useInView(options);
  const [ref2, inView2] = useInView(options);
  const [ref3, inView3] = useInView(options);
  const [ref4, inView4] = useInView(options);
  const [ref5, inView5] = useInView(options);

  const absImg = [
    {
      img: require("../../assets/images/ab1.webp"),
      name: "Keynote Speeches",
    },
    {
      img: require("../../assets/images/ab2.webp"),
      name: "Expert Talks",
    },
    {
      img: require("../../assets/images/ab3.webp"),
      name: "Panel Discussions",
    },
    {
      img: require("../../assets/images/ab4.webp"),
      name: "Fireside Chats",
    },
    {
      img: require("../../assets/images/ab5.webp"),
      name: "Networking",
    },
    {
      img: require("../../assets/images/ab6.webp"),
      name: "Award Night",
    },
  ];

  const coreCommittee = [
    {
      img: require("../../assets/images/c1.webp"),
      name: "Manikandan",
      pos: "Creative Head",
      insta: "https://www.instagram.com/mani_feb?igsh=ZDB5Nml2bHB4dXM2",
      facebook: "https://www.facebook.com/share/1FDjEzTbwc/?mibextid=qi2Omg",
      linkedin:
        "https://www.linkedin.com/in/mani-kandan-2b4289129?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
    },

    {
      img: require("../../assets/images/c2.webp"),
      name: "Azhagarasan",
      pos: "Senior Video Editor",
      insta:
        "https://www.instagram.com/azhagarasan_r?igsh=MWo1cmFvaXk0M2dmbQ==",
      facebook: "https://www.facebook.com/share/1HZDhHQt2r/",
      linkedin:
        "https://www.linkedin.com/in/azhagarasan-ravi-3325a123a?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
    },
    {
      img: require("../../assets/images/c3.webp"),
      name: "Pradeep Kumar",
      pos: "Team Leader, Sales",
      insta:
        "https://www.instagram.com/anytime_entertainer?igsh=YTBiNGlmdm04bWM2",
      facebook: "",
      linkedin:
        "https://www.linkedin.com/in/pradeep-kumar-g-17a5a31b3?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
    },
    {
      img: require("../../assets/images/c4.webp"),
      name: "Arun Kumar M G",
      pos: "Founder, cr8ive.in",
      insta: "https://www.instagram.com/arunkumar_m_g/",
      facebook: "https://www.facebook.com/arunkumarmg8",
      linkedin:
        "https://www.linkedin.com/in/arunkumar-mg/?originalSubdomain=in",
    },
    {
      img: require("../../assets/images/c5.webp"),
      name: "Devapran Gopalakrishnan",
      pos: "Co-Founder & CEO, cr8ive.in",
      insta: "https://www.instagram.com/nithis_deva/",
      facebook: "https://www.facebook.com/nithisdeva.pran",
      linkedin:
        "https://www.linkedin.com/in/devapran-gopalakrishnan-782bb717a/",
    },
  ];

  const learnData = [
    {
      text: "Marketing strategies from the top <br className='br-tag' /> Growth Experts",
    },
    {
      text: "Frameworks that convert at scale",
    },
    {
      text: "Real-world AI applications that <br className='br-tag' /> boost Productivity and Profit",
    },
    {
      text: "Content and Creator Monetization <br className='br-tag' /> Methods",
    },
    {
      text: "Business insights from Leading <br className='br-tag' /> Entrepreneurs",
    },
  ];

  const For_Whom_Array = [
    "Digital Marketers & Sales Professionals",
    "Digital Agencies",
    "Digital Aspirants & Enthusiasts",
    "Coaches & Consultants",
    "Business Owners who want to<br className='br-tag' /> Scale using Digital Platforms",
    "E-Com Business Owners",
    "Content Creators & Influencers",

    "Community Members who want be <br className='br-tag' /> in the dynamic zone and Scale their Business",
  ];
  const For_Whom = [...For_Whom_Array, ...For_Whom_Array, ...For_Whom_Array];

  const scrollOrganizer = [
    {
      img: require("../../assets/images/o1.webp"),
      img2: require("../../assets/images/eo1.webp"),
      name: "G D Dharaneetharan",
      pos: "Founder - Social Eagle & 21DC Community",
      insta: "https://www.instagram.com/dharaneetharan/?hl=en",
      youtube: "https://www.youtube.com/results?search_query=dharaneedharan",
      facebook: "https://www.facebook.com/dharaneetharangd/",
      linkedin:
        "https://www.linkedin.com/in/dharaneetharan/?originalSubdomain=in",
    },
    {
      img: require("../../assets/images/o2.webp"),
      img2: require("../../assets/images/eo2.webp"),
      name: "Vishnuhari",
      pos: "Co-Founder - Social Eagle, EX AIRBNB",

      insta: "https://www.instagram.com/imvishnuhari/",
      facebook: "https://www.facebook.com/imvishnuhari?mibextid=ZbWKwL",
      linkedin: "https://www.linkedin.com/in/vishnuhari-harikumar/",
    },
    {
      img: require("../../assets/images/o3.webp"),
      img2: require("../../assets/images/eo3.webp"),
      name: "Sathish S",
      pos: "Director of Operations, Social eagle",
      insta: "https://www.instagram.com/socialeagleofficial/?hl=en",
      facebook: "https://www.facebook.com/SocialEagleOfficial/",
      linkedin: "https://www.linkedin.com/company/socialeagle/",
    },
    {
      img: require("../../assets/images/o4.webp"),
      img2: require("../../assets/images/eo4.webp"),
      name: "Sudharsanan Ganapathy",
      pos: "Founder - The Social Company",
      insta: "https://www.instagram.com/sudharsananganapathy/?hl=en",
      youtube:
        "https://www.youtube.com/results?search_query=sudharsanan+ganapathy",
      facebook: "https://www.facebook.com/sudharsananganapathy",
      linkedin:
        "https://www.linkedin.com/in/sudharsananganapathy/?originalSubdomain=in",
    },
    {
      img: require("../../assets/images/o5.webp"),
      img2: require("../../assets/images/eo5.webp"),
      name: "Shatheeshram Ganapathy",
      pos: "Director of Sales, Social eagle",
      insta:
        "https://www.instagram.com/shatheeshramganapathy/?igsh=MW5nYzc4Nmh3OXJvdQ%3D%3D#",
      facebook: "https://www.facebook.com/shatheeshram?mibextid=ZbWKwL",
      linkedin: "https://www.linkedin.com/in/shatheeshram-ganapathy-a7476473/",
    },
  ];
  const [selectedOranizer, setSelectedOrganizer] = useState(
    scrollOrganizer?.[0]?.img
  );

  return (
    <div>
      <div className="about-section">
        <div className="about-count-section py-5">
          <div className="container-fluid">
            <div>
              <h4 className="font-bold text-center text-light pt-2 pb-4">
                Tamil Nadu’s Biggest and Most Valuable Digital Summit
              </h4>
            </div>
            <div className="row pt-lg-0 pt-md-4">
              <div className="col-lg-2 col-md-4 col-6">
                <div className="count-home-sec" ref={ref1}>
                  <h4 className="count-heading">
                    {inView1 && (
                      <CountUp start={0} end={13} duration={2} delay={0} />
                    )}
                  </h4>
                  <p className="mb-0 count-para">Sessions</p>
                </div>
              </div>
              <div className="col-lg-2  col-md-4 col-6">
                <div className="count-home-sec" ref={ref2}>
                  <h4 className="count-heading">
                    {inView2 && (
                      <CountUp start={0} end={25} duration={2} delay={0} />
                    )}{" "}
                    +
                  </h4>
                  <p className="mb-0 count-para">Industry Experts</p>
                </div>
              </div>
              <div className="col-lg-2  col-md-4 col-6">
                <div className="count-home-sec" ref={ref3}>
                  <h4 className="count-heading">
                    {inView3 && (
                      <CountUp start={0} end={3} duration={2} delay={0} />
                    )}
                  </h4>
                  <p className="mb-0 count-para">Panel Discussions</p>
                </div>
              </div>
              <div className="col-lg-2  col-md-4 col-6">
                <div className="count-home-sec" ref={ref4}>
                  <h4 className="count-heading">
                    {inView4 && (
                      <CountUp start={0} end={50} duration={2} delay={0} />
                    )}{" "}
                    +
                  </h4>
                  <p className="mb-0 count-para">Awardees</p>
                </div>
              </div>
              <div className="col-lg-2  col-md-4 col-6">
                <div className="count-home-sec" ref={ref5}>
                  <h4 className="count-heading">
                    {inView5 && (
                      <CountUp start={0} end={500} duration={2} delay={0} />
                    )}{" "}
                    +
                  </h4>
                  <p className="mb-0 count-para">Attendees</p>
                </div>
              </div>
              <div className="col-lg-2  col-md-4 col-6">
                <div className="count-home-sec">
                  <h4 className="count-heading ">∞</h4>
                  <p className="mb-0 count-para">Value</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="gradient-border"></div>
        <div className="about-content-section ">
          <div className="position-relative mb-5">
            <img className="design-img-pos" src={DesignImg} alt="" />
            <div className="container">
              <div className="row">
                <div className="col-lg-5 mb-lg-0 mb-md-0 mb-4">
                  <div className="px-lg-5 px-md-3 px-0 ">
                    <h2 className="about-small-heading">About</h2>
                    <h1 className="about-big-heading">TNDS 2025</h1>
                  </div>
                </div>
                <div className="col-lg-7 ">
                  <div>
                    <p>
                      ‘Tamil Nadu Digital Summit’ is a Pure Value-Driven and
                      Community-based <br className="br-tag" /> event.
                    </p>
                    <p>
                      2 Days and with Special Guests including Entrepreneurs,
                      Business Leaders,
                      <br className="br-tag" /> Digital Enthusiasts, Coaches,
                      Creators, and Influencers, TNDS promotes{" "}
                      <br className="br-tag" /> collaboration and inspires
                      growth.
                    </p>
                    <p>
                      There will be Panel discussions, Practical Frameworks,
                      Keynote Speeches <br className="br-tag" /> and
                      battle-tested knowledge shared to 450+ carefully selected
                      Digital <br className="br-tag" /> Entrepreneurs,
                      Freelancers, Creators and Business Owners.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container ">
            <div className="row">
              {absImg.map((item, index) => {
                return (
                  <div
                    className="col-lg-2 col-md-4 col-6 mb-lg-0 mb-md-4 mb-4 "
                    key={index}
                  >
                    <div className="position-relative">
                      <img className="ab-imgs" src={item?.img} alt="" />
                      <h6 className="ab-img-text">{item?.name}</h6>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <div className="quote">
        <div className="bg-blue-gradient">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-8 col-md-12">
                <div className="position-relative">
                  <i class="quote-left fa-solid fa-quote-left"></i>
                  <i class="quote-right fa-solid fa-quote-right"></i>
                  <h5 className="quote-content">
                    Tamil Nadu's biggest and most valuable digital event is also{" "}
                    <br className="br-tag" />
                    aimed at helping make Tamil Nadu and its Entrepreneurs{" "}
                    <br className="br-tag" /> ready for the Digital and AI
                    Future.
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="gradient-border"></div>
      </div>
      <div className="recape-section">
        <div className="container">
          <div className="recap-heading-section">
            <img
              className="recap-design-img"
              src={require("../../assets/images/recap/recap-design.webp")}
              alt=""
            />
            <h2 className="recap-heading">Recap TNDS 2024</h2>
          </div>
          <div className="row mb-70">
            <div className="col-lg-4 col-md-12">
              <div className="row g-3">
                <div className="col-lg-7 col-md-7 col-7">
                  <img
                    className="w-100 h-auto mb-3"
                    src={require("../../assets/images/recap/r1.webp")}
                    alt=""
                  />
                  <img
                    className="w-100 h-auto"
                    src={require("../../assets/images/recap/r2.webp")}
                    alt=""
                  />
                </div>
                <div className="col-lg-5 col-md-5 col-5 mt-auto">
                  <img
                    className="w-100 h-auto"
                    src={require("../../assets/images/recap/r3.webp")}
                    alt=""
                  />
                </div>
                <div className="col-lg-12 col-md-12 col-12">
                  <img
                    className="w-100 h-auto"
                    src={require("../../assets/images/recap/r4.webp")}
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-12 mt-auto">
              <div className="d-flex">
                <img
                  className="recap-center-img "
                  src={require("../../assets/images/recap/r9.webp")}
                  alt=""
                />
              </div>
            </div>
            <div className="col-lg-4 col-md-12">
              <div className="row g-3">
                <div className="col-lg-5 col-md-5 col-5 mt-auto">
                  <img
                    className="w-100 h-auto"
                    src={require("../../assets/images/recap/r6.webp")}
                    alt=""
                  />
                </div>
                <div className="col-lg-7 col-md-7 col-7">
                  <img
                    className="w-100 h-auto mb-3"
                    src={require("../../assets/images/recap/r5.webp")}
                    alt=""
                  />
                  <img
                    className="w-100 h-auto"
                    src={require("../../assets/images/recap/r7.webp")}
                    alt=""
                  />
                </div>
                <div className="col-lg-12 col-md-12 col-12">
                  <img
                    className="w-100 h-auto"
                    src={require("../../assets/images/recap/r8.webp")}
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row gap-5 justify-content-center">
            <div className="col-lg-5">
              <div>
                <h5 className="highlight-text">Highlights from TNDS 2024</h5>
                <iframe
                  style={{ borderRadius: "20px" }}
                  width="100%"
                  height="325"
                  src="https://www.youtube.com/embed/msMaCxeLlSw?si=xcbK8OcqpPcN5rbw"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerpolicy="strict-origin-when-cross-origin"
                  allowfullscreen
                ></iframe>
              </div>
            </div>

            <div className="col-lg-5">
              <div>
                <h5 className="highlight-text">Guest Stories from TNDS 2024</h5>
                <iframe
                  style={{ borderRadius: "20px" }}
                  width="100%"
                  height="325"
                  src="https://www.youtube.com/embed/XlDKvL40sds?si=923QZj-QrPZ5O0TE"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerpolicy="strict-origin-when-cross-origin"
                  allowfullscreen
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="for-whom-section">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-5 pt-5">
              <div>
                <h2 className="text-the-heading text-dark text-start">For</h2>
                <div className="significance-heading-section ms-0 mb-3">
                  <h2 className="text-significance-heading core-commite-heading text-start">
                    WHOM?
                  </h2>
                </div>
                <div className="mb-4 px-5">
                  <img className="for-whom-design-img" src={DesignImg} alt="" />
                </div>
              </div>
            </div>
            <div className="col-lg-5">
              <div className="vertical_marque blur">
                <div class="slider">
                  {For_Whom.map((item, index) => (
                    <div key={index}>
                      <div className="learn-left-content whom-ticket-section pb-4 mb-4">
                        <div className="bg-rounded-learn-num whom-ticket-round">
                          <img
                            className="whom-ticket-img tic-black"
                            src={require("../../assets/images/ticket-black.webp")}
                            alt=""
                          />
                          <img
                            className="whom-ticket-img tic-gold"
                            src={require("../../assets/images/ticket-gold.webp")}
                            alt=""
                          />
                        </div>
                        <h5
                          className="learn-content text-start"
                          dangerouslySetInnerHTML={{ __html: item }}
                        ></h5>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="gradient-border"></div>
      <div className="significance-section">
        <div className="container">
          <div className="mb-70">
            <h2 className="text-the-heading">The</h2>
            <div className="significance-heading-section ">
              <img
                className="significance-design-img"
                src={require("../../assets/images/recap/recap-design.webp")}
                alt=""
              />
              <h2 className="text-significance-heading">SIGNIFICANCE</h2>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-3 col-md-4">
              <div className="signi-card">
                <div className="signi-img-sec">
                  <img
                    className="signi-img"
                    src={require("../../assets/images/significance-icon/s1.webp")}
                    alt=""
                  />
                </div>
                <p className="signi-text">Power of Community</p>
              </div>
            </div>
            <div className="col-lg-3 col-md-4">
              <div className="signi-card">
                <div className="signi-img-sec">
                  <img
                    className="signi-img"
                    src={require("../../assets/images/significance-icon/s2.webp")}
                    alt=""
                  />
                </div>
                <p className="signi-text">High Impact Speakers</p>
              </div>
            </div>
            <div className="col-lg-3 col-md-4">
              <div className="signi-card">
                <div className="signi-img-sec">
                  <img
                    className="signi-img"
                    src={require("../../assets/images/significance-icon/s3.webp")}
                    alt=""
                  />
                </div>
                <p className="signi-text">Closer Networking Opportunities</p>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-3 col-md-6">
              <div className="signi-card">
                <div className="signi-img-sec">
                  <img
                    className="signi-img"
                    src={require("../../assets/images/significance-icon/s4.webp")}
                    alt=""
                  />
                </div>
                <p className="signi-text">Innovation & Trends</p>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="signi-card">
                <div className="signi-img-sec">
                  <img
                    className="signi-img"
                    src={require("../../assets/images/significance-icon/s5.webp")}
                    alt=""
                  />
                </div>
                <p className="signi-text">Impact & Influence</p>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="signi-card">
                <div className="signi-img-sec">
                  <img
                    className="signi-img"
                    src={require("../../assets/images/significance-icon/s6.webp")}
                    alt=""
                  />
                </div>
                <p className="signi-text">Inspiration that Lasts Long</p>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="signi-card">
                <div className="signi-img-sec">
                  <img
                    className="signi-img"
                    src={require("../../assets/images/significance-icon/s7.webp")}
                    alt=""
                  />
                </div>
                <p className="signi-text">Interaction with the "Who is Who"</p>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-3 col-md-4">
              <div className="signi-card">
                <div className="signi-img-sec">
                  <img
                    className="signi-img"
                    src={require("../../assets/images/significance-icon/s8.webp")}
                    alt=""
                  />
                </div>
                <p className="signi-text">High Energy & Optimism</p>
              </div>
            </div>
            <div className="col-lg-3 col-md-4">
              <div className="signi-card">
                <div className="signi-img-sec">
                  <img
                    className="signi-img"
                    src={require("../../assets/images/significance-icon/s9.webp")}
                    alt=""
                  />
                </div>
                <p className="signi-text">The Powerful "Eagle Awards"</p>
              </div>
            </div>
            <div className="col-lg-3 col-md-4">
              <div className="signi-card">
                <div className="signi-img-sec">
                  <img
                    className="signi-img"
                    src={require("../../assets/images/significance-icon/s10.webp")}
                    alt=""
                  />
                </div>
                <p className="signi-text">Actionable Frameworks to Implement</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="event-organizer">
        <div className="bg-blue-gradient py-lg-3 py-md-5 py-5">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-5  my-auto">
                <h2 className="get-ready-df-text font-regular">Event</h2>
                <div className="significance-heading-section text-start ms-0 me-auto">
                  <img
                    className="significance-design-img"
                    src={require("../../assets/images/recap/recap-design.webp")}
                    alt=""
                  />
                  <h2 className="get-ready-df-text">
                    ORGANI<span className="bottom-border-gradient">SERS</span>
                  </h2>
                </div>
              </div>
              <div className="col-lg-7 col-md-12 my-auto">
                <div className="owlcard-organizer">
                  <OwlCarousel
                    className="owl-theme"
                    loop={true}
                    items={1}
                    dots={false}
                    nav={false}
                    margin={20}
                    center={true}
                    autoplay={true}        
                    responsive={{
                      360: {
                        items: "1",
                      },
                      414: {
                        items: "1",
                      },
                      670: {
                        items: "2",
                      },
                      992: {
                        items: "3",
                      },
                      1200: {
                        items: "3",
                      },
                      1300: {
                        items: "3",
                      },
                    }}
                  >
                    {scrollOrganizer.map((item, index) => {
                      return (
                        <div className="event-organizer-card" key={index} 
                       
                        >
                          <img
                            className="team-open-card-modal"
                            src={item?.img}
                            alt={""}
                          />
                          <div className="modal-bg-speaker-card">
                            <h6 className="core-card-text text-dark ">
                              {item?.name}
                            </h6>
                            <p className="core-card-subtext h-auto text-dark text-small">
                              {item?.pos}
                            </p>
                            <div className="social-media-organize-sec">
                              <div className="d-flex justify-content-center gap-3">
                                {item.insta && (
                                  <div>
                                    <a
                                      className="our-team-sm-redirect"
                                      href={item.insta}
                                      target="_blank"
                                    >
                                      <i class="fa-brands small fa-instagram"></i>
                                    </a>
                                  </div>
                                )}
                                {item.facebook && (
                                  <div>
                                    <a
                                      className="our-team-sm-redirect"
                                      href={item.facebook}
                                      target="_blank"
                                    >
                                      <i class="fa-brands small fa-facebook"></i>
                                    </a>
                                  </div>
                                )}
                                {item.linkedin && (
                                  <div>
                                    <a
                                      className="our-team-sm-redirect"
                                      href={item.linkedin}
                                      target="_blank"
                                    >
                                      <i class="fa-brands small fa-linkedin-in"></i>
                                    </a>
                                  </div>
                                )}
                                {item.youtube && (
                                  <div>
                                    <a
                                      className="our-team-sm-redirect"
                                      href={item.youtube}
                                      target="_blank"
                                    >
                                      <i class="fa-brands small fa-youtube"></i>
                                    </a>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </OwlCarousel>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="gradient-border"></div>
      </div> */}
      <div className="event-organizer">
        <div className="bg-blue-gradient pt-lg-5 pb-lg-3 py-md-5 py-5">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-9 col-md-8  my-auto">
                <div className="significance-heading-section  pb-4 text-start ms-0 me-auto">
                  <img
                    className="significance-design-img"
                    src={require("../../assets/images/recap/recap-design.webp")}
                    alt=""
                  />
                  <h2 className="get-ready-df-text">
                    ORGANI<span className="bottom-border-gradient">SERS</span>
                  </h2>
                </div>
                <div className="row justify-content-center">
                  {scrollOrganizer.map((item, index) => {
                    return (
                      <div
                        onClick={() => setSelectedOrganizer(item?.img)}
                        className={`event-organizer-card-2 mb-3 col col-md-4 col-6`}
                        style={{
                          opacity: selectedOranizer === item?.img ? 1 : 0.35,
                        }}
                        key={index}
                        onMouseEnter={() => setSelectedOrganizer(item?.img)}
                        onMouseLeave={() => setSelectedOrganizer(item?.img)}
                      >
                        <img
                          className="org-team-card-2-img"
                          src={item?.img2}
                          alt={""}
                        />
                        <div className="">
                          <h6 className="core-card-text mb-1">{item?.name}</h6>
                          <p
                            className="core-card-subtext h-auto  text-small"
                            dangerouslySetInnerHTML={{ __html: item?.pos }}
                          ></p>
                          <div className="social-media-organize-sec">
                            <div className="d-flex justify-content-center gap-3">
                              {item.insta && (
                                <div>
                                  <a
                                    className="our-team-sm-redirect text-light"
                                    href={item.insta}
                                    target="_blank"
                                  >
                                    <i class="fa-brands small fa-instagram"></i>
                                  </a>
                                </div>
                              )}
                              {item.facebook && (
                                <div>
                                  <a
                                    className="our-team-sm-redirect text-light"
                                    href={item.facebook}
                                    target="_blank"
                                  >
                                    <i class="fa-brands small fa-facebook"></i>
                                  </a>
                                </div>
                              )}
                              {item.linkedin && (
                                <div>
                                  <a
                                    className="our-team-sm-redirect text-light"
                                    href={item.linkedin}
                                    target="_blank"
                                  >
                                    <i class="fa-brands small fa-linkedin-in"></i>
                                  </a>
                                </div>
                              )}
                              {item.youtube && (
                                <div>
                                  <a
                                    className="our-team-sm-redirect text-light"
                                    href={item.youtube}
                                    target="_blank"
                                  >
                                    <i class="fa-brands small fa-youtube"></i>
                                  </a>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="col-lg-3 col-md-4 my-auto">
                <div className="owlcard-organizer">
                  <img
                    className="organizer-side-img"
                    src={selectedOranizer}
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="gradient-border"></div>
      </div>
      <div className="core-committee-section">
        <div className="container">
          <div className="mb-70">
            <h2 className="text-the-heading text-dark">Core</h2>
            <div className="significance-heading-section ">
              <img
                className="significance-design-img"
                src={require("../../assets/images/recap/recap-design.webp")}
                alt=""
              />
              <h2 className="text-significance-heading core-commite-heading">
                COMMITTEE
              </h2>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-9">
              <div className="row  justify-content-center">
                {coreCommittee.map((item, index) => {
                  return (
                    <div className="col-lg-4 col-md-5" key={index + 1}>
                      <div className="core-com-card">
                        <div>
                          <img
                            className="w-100 h-auto img-top-team"
                            src={item?.img}
                            alt=""
                          />
                        </div>
                        <div className="core-com-inner-card">
                          <h6 className="core-card-text">{item?.name}</h6>
                          <div className="core-card-subtext-head">
                            <p className="core-card-subtext">{item?.pos}</p>
                          </div>
                          <div className="core-com-sm-card">
                            <div className="core-com-sm">
                              {item?.insta?.length > 0 && (
                                <a
                                  className="core-comi-redirect"
                                  href={item?.insta}
                                  target="_blank"
                                >
                                  <i class="fa-brands fa-instagram"></i>
                                </a>
                              )}
                              {item?.facebook?.length > 0 && (
                                <a
                                  className="core-comi-redirect"
                                  href={item?.facebook}
                                  target="_blank"
                                >
                                  <i class="fa-brands fa-facebook"></i>
                                </a>
                              )}
                              {item?.linkedin?.length > 0 && (
                                <a
                                  className="core-comi-redirect"
                                  href={item?.linkedin}
                                  target="_blank"
                                >
                                  <i class="fa-brands fa-linkedin-in"></i>
                                </a>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="Speakers-lineup scroll-top-margin" id="Speakers">
        <div className="bg-blue-gradient">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-4 my-auto">
                <div className="significance-heading-section text-start ms-0 me-auto">
                  <img
                    className="significance-design-img"
                    src={require("../../assets/images/recap/recap-design.webp")}
                    alt=""
                  />
                  <h2 className="get-ready-df-text">
                    SPEA<span className="bottom-border-gradient">KERS</span>
                  </h2>
                </div>
                <h2 className="get-ready-df-text font-regular">Line-Up </h2>
              </div>
              <div className="col-lg-8 my-auto">
                <div>
                  <h5 className="learn-content text-light mb-3">
                    Expect high-profile speakers who are Global Marketing
                    Leaders, Founders, Entrepreneurs,
                    <br className="br-tag" /> Consultants, Coaches, Creators,
                    and Influencers
                  </h5>
                  <h5 className="learn-content text-light">
                    Each speaker is handpicked for their ability to deliver
                    high-signal insights, no fluff, no filler.
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="gradient-border"></div>
        <div>
          <SpeakersLineup />
        </div>
      </div>
      <div className="what-you-learn">
        <div className="container">
          <div className="position-relative ">
            <h2 className="text-the-heading text-dark">What You’ll</h2>
            <div className="mb-4">
              <img className="design-what-you-learn" src={DesignImg} alt="" />
              <h2 className="text-significance-heading core-commite-heading">
                LEARN
              </h2>
            </div>
          </div>
          <div className="learn-round-heading mb-5">
            <h5 className="learn-content">
              Practical Insights. Actionable Frameworks
            </h5>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-9 col-md-12">
              <div className="row justify-content-between">
                {learnData.map((item, index) => {
                  return (
                    <div className="col-lg-5 col-md-6" key={index}>
                      <div className="learn-left-content">
                        <div className="bg-rounded-learn-num">
                          <h1 className="rounded-learn-num">{index + 1}</h1>
                        </div>
                        <h5
                          className="learn-content"
                          dangerouslySetInnerHTML={{ __html: item.text }}
                        ></h5>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="hilton">
        <div className="bg-blue-gradient">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-7 col-md-7 my-auto">
                <h2 className="get-ready-df-text">Get Ready for </h2>
                <div className="significance-heading-section text-start ms-0 me-auto">
                  <img
                    className="significance-design-img"
                    src={require("../../assets/images/recap/recap-design.webp")}
                    alt=""
                  />
                  <h2 className="get-ready-df-text">
                    the Digital{" "}
                    <span className="bottom-border-gradient">Future</span>
                  </h2>
                </div>
              </div>
              <div className="col-lg-4 col-md-5 my-auto">
                <div>
                  <button
                    onClick={() => navigate("/Ticket")}
                    className="btn banner-btn big-btn "
                  >
                    <div className="figure">
                      <img className="ticket-white" src={TicketWhite} alt="" />
                      <img className="ticket-black" src={TicketBlack} alt="" />
                    </div>
                    <span>Buy Tickets</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="gradient-border"></div>
        <div className="hilton-section">
          <div className="container">
            <div className="hilton-bg">
              <div className="row justify-content-end">
                <div className="col-lg-5">
                  <div className="">
                    <div className="loc-hilton mb-3">
                      <img
                        className="blue-location"
                        src={require("../../assets/images/blue-location.webp")}
                      />
                      <h5 className="hilton-loc-text">
                        Hilton, Chennai | 26 & 27 April 2025
                      </h5>
                    </div>
                    <img
                      className="w-100 h-auto mb-lg-0 mb-md-5 mb-5"
                      src={require("../../assets/images/hilton.webp")}
                    />
                  </div>
                </div>
                <div className="col-lg-1"></div>
                <div className="col-lg-5 my-auto">
                  <div>
                    <h2 className="text-the-heading text-dark text-start">
                      Why in
                    </h2>
                    <div className="significance-heading-section ms-0 mb-3">
                      <img
                        className="significance-design-img"
                        src={require("../../assets/images/recap/recap-design.webp")}
                        alt=""
                      />
                      <h2 className="text-significance-heading core-commite-heading text-start">
                        Chennai?
                      </h2>
                    </div>
                    <div className="bg-hub-text mb-4">
                      <h5 className="hilton-loc-text">
                        A Rising Hub for Digital Innovation
                      </h5>
                    </div>
                    <p className="hilton-para">
                      Chennai is fast becoming a strategic player in the global
                      tech <br className="br-tag" /> landscape, rich in talent,
                      innovation, and ambition.
                    </p>
                    <p className="hilton-para">
                      Set in the 5-star Hilton, this summit combines world-class
                      <br className="br-tag" />
                      facilities with the energy of one of India’s
                      fastest-growing
                      <br className="br-tag" /> digital ecosystems.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeContent;
