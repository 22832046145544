import React from "react";
import Location from "../assets/images/blue-location.webp";
const AgendaContent = () => {
  return (
    <div className="margin-top-part">
      <div className="agenda-page ">
        <div className="container">
          <div className="">
            <h2 className="text-the-heading text-dark">Core</h2>
            <div className="significance-heading-section mb-4 ">
              <img
                className="significance-design-img"
                src={require("../assets/images/recap/recap-design.webp")}
                alt=""
              />
              <h2 className="text-significance-heading core-commite-heading">
                COMMITTEE
              </h2>
            </div>
            <div className="">
              <p className="banner-rounded-content text-dark border-dark mb-5">
                <img className="location-img" src={Location} alt=""></img>
                <span>Hilton, Chennai | 26 & 27 April 2025</span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="agenda-table-bg">
        <div className="container mb-5 pb-5">
          <h2 className="font-semibold text-center text-dark mb-70">
            Day 1 -{" "}
            <span className="bottom-border-gradient text-center font-semibold text-dark">
              April 26, 2025
            </span>
          </h2>
          <div className="row justify-content-center">
            <div className="col-lg-12 agenda-table-overflow ">
              <table className="w-100 agenda-table">
                <tr className="agenda-first-row">
                  <td className="rounded-border-heading w-time ">
                    <p className="table-heading">Time</p>
                  </td>
                  <td className="rounded-border-heading w-topic">
                    <p className="table-heading">Topic</p>
                  </td>
                  <td className="rounded-border-heading w-speaker">
                    <p className="table-heading">Speaker / Panel Discussion</p>
                  </td>
                </tr>
                <tr>
                  <td className="rounded-border-content w-time ">
                    <p className="table-para-content ">08:00 AM - 09:00 AM</p>
                  </td>
                  <td className="rounded-border-content  w-topic">
                    <p className="table-para-content text-start">
                      Welcome Address & Inauguration + 7 min Video
                    </p>
                  </td>
                  <td className="rounded-border-content w-speaker">
                    <p className="table-para-content text-start pt-3">
                      <span className="font-bold ">Dharaneetharan G D</span>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td className="rounded-border-yellow w-time ">
                    <p className="table-yellow-content">09:00 AM - 09:30 AM</p>
                  </td>
                  <td className="rounded-border-yellow w-speaker" colSpan={2}>
                    <p className="table-yellow-content">Registration</p>
                  </td>
                </tr>
                <tr>
                  <td className="rounded-border-content w-time ">
                    <p className="table-para-content ">09:30 AM - 10:00 AM</p>
                  </td>
                  <td className="rounded-border-content  w-topic">
                    <p className="table-para-content text-start"></p>
                  </td>
                  <td className="rounded-border-content w-speaker">
                    <p className="table-para-content text-start pt-3"></p>
                  </td>
                </tr>
                <tr>
                  <td className="rounded-border-content w-time ">
                    <p className="table-para-content pt-3 ">
                      10:00 AM - 10:45 AM
                    </p>
                  </td>
                  <td className="rounded-border-content  w-topic">
                    <p className="table-para-content pt-3  text-start">
                      Win Fast, Retire Young
                    </p>
                  </td>
                  <td className="rounded-border-content w-speaker">
                    <p className="table-para-content  text-start pt-3">
                      <span className="font-bold ">Himanshu Agarwal </span>
                    </p>
                  </td>
                </tr>

                <tr>
                  <td className="rounded-border-yellow w-time ">
                    <p className="table-yellow-content">10:45 AM - 11:00 AM</p>
                  </td>
                  <td className="rounded-border-yellow w-speaker" colSpan={2}>
                    <p className="table-yellow-content">BREAK</p>
                  </td>
                </tr>
                <tr>
                  <td className="rounded-border-content w-time ">
                    <p className="table-para-content ">11:00 AM - 11:30 AM</p>
                  </td>
                  <td className="rounded-border-content  w-topic">
                    <p className="table-para-content text-start">
                      How to play the Big Game (Networking and Leveraging)
                    </p>
                  </td>
                  <td className="rounded-border-content w-speaker">
                    <p className="table-para-content text-start">
                      <span className="font-bold  text-start">CKK</span>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td className="rounded-border-content w-time ">
                    <p className="table-para-content ">11:30 AM - 12:10 PM</p>
                  </td>
                  <td className="rounded-border-content  w-topic">
                    <p className="table-para-content  text-start">
                      SE Students
                    </p>
                  </td>
                  <td className="rounded-border-content w-speaker">
                    <p className="table-para-content text-start">
                      Panel Discussion
                    </p>
                  </td>
                </tr>
                <tr>
                  <td className="rounded-border-content w-time ">
                    <p className="table-para-content ">12:10 PM - 12:45 PM</p>
                  </td>
                  <td className="rounded-border-content  w-topic">
                    <p className="table-para-content  text-start">Al</p>
                  </td>
                  <td className="rounded-border-content w-speaker">
                    <p className="table-para-content text-start">
                      Dr Deepak Renganathan
                    </p>
                  </td>
                </tr>
                <tr>
                  <td className="rounded-border-yellow w-time ">
                    <p className="table-yellow-content">12:45 PM - 01:30 PM</p>
                  </td>
                  <td className="rounded-border-yellow w-speaker" colSpan={2}>
                    <p className="table-yellow-content">LUNCH</p>
                  </td>
                </tr>
                <tr>
                  <td className="rounded-border-content w-time ">
                    <p className="table-para-content ">01:30 PM - 02:05 PM</p>
                  </td>
                  <td className="rounded-border-content  w-topic">
                    <p className="table-para-content  text-start"></p>
                  </td>
                  <td className="rounded-border-content w-speaker">
                    <p className="table-para-content  text-start">
                      <span className="font-bold ">Dr Kannan Giriesh </span>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td className="rounded-border-content w-time ">
                    <p className="table-para-content ">02:05 PM - 02:45 PM</p>
                  </td>
                  <td className="rounded-border-content  w-topic">
                    <p className="table-para-content text-start">SE Students</p>
                  </td>
                  <td className="rounded-border-content w-speaker">
                    <p className="table-para-content text-start">
                      Panel Discussion
                    </p>
                  </td>
                </tr>
                <tr>
                  <td className="rounded-border-yellow w-time ">
                    <p className="table-yellow-content">02:45 PM - 03:10 PM</p>
                  </td>
                  <td className="rounded-border-yellow w-speaker" colSpan={2}>
                    <p className="table-yellow-content">BREAK</p>
                  </td>
                </tr>
                <tr>
                  <td className="rounded-border-content w-time ">
                    <p className="table-para-content ">03:10 PM - 03:45 PM</p>
                  </td>
                  <td className="rounded-border-content  w-topic">
                    <p className="table-para-content text-start">
                      Why should you Create Content?
                    </p>
                  </td>
                  <td className="rounded-border-content w-speaker">
                    <p className="table-para-content text-start">
                      <span className="font-bold  text-start">
                        Suren SuccessGyan
                      </span>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td className="rounded-border-content w-time ">
                    <p className="table-para-content ">03:40 PM - 04:15 PM</p>
                  </td>
                  <td className="rounded-border-content  w-topic">
                    <p className="table-para-content text-start">
                      Entrepreneur Mindset
                    </p>
                  </td>
                  <td className="rounded-border-content w-speaker">
                    <p className="table-para-content text-start">
                      <span className="font-bold  text-start">
                        Latha Pandiarajan
                      </span>
                    </p>
                  </td>
                </tr>

                <tr>
                  <td className="rounded-border-content w-time ">
                    <p className="table-para-content ">04:15 PM - 05:00 PM</p>
                  </td>
                  <td className="rounded-border-content  w-topic">
                    <p className="table-para-content text-start">
                      Entrepreneur Mindset
                    </p>
                  </td>
                  <td className="rounded-border-content w-speaker">
                    <p className="table-para-content text-start">
                      <span className="font-bold  text-start">
                        Sidharth Rajasekar
                      </span>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td className="rounded-border-content w-time ">
                    <p className="table-para-content ">05:00 PM - 05:30 PM</p>
                  </td>
                  <td className="rounded-border-content  w-topic">
                    <p className="table-para-content text-start">Women E Com</p>
                  </td>
                  <td className="rounded-border-content w-speaker">
                    <p className="table-para-content text-start">
                      Panel Discussion
                    </p>
                  </td>
                </tr>
                <tr>
                  <td className="rounded-border-content w-time bg-transf-blue">
                    <p className="table-para-content font-bold text-dark">
                      06:15PM
                    </p>
                  </td>
                  <td
                    className="rounded-border-content w-speaker bg-transf-blue"
                    colSpan={2}
                  >
                    <p className="table-para-content text-dark font-bold">
                      Eagle Awards (Recognising the Established and Emerging
                      Digital Superstars)
                    </p>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
        <div className="container mb-70 pb-4">
          <h2 className="font-semibold text-center text-dark mb-70">
            Day 2 -{" "}
            <span className="bottom-border-gradient text-center font-semibold text-dark">
              April 27, 2025
            </span>
          </h2>
          <div className="row justify-content-center">
            <div className="col-lg-12 agenda-table-overflow ">
              <table className="w-100 agenda-table">
                <tr className="agenda-first-row">
                  <td className="rounded-border-heading w-time ">
                    <p className="table-heading">Time</p>
                  </td>
                  <td className="rounded-border-heading w-topic">
                    <p className="table-heading">Topic</p>
                  </td>
                  <td className="rounded-border-heading w-speaker">
                    <p className="table-heading">Speaker / Panel Discussion</p>
                  </td>
                </tr>
                <tr>
                  <td className="rounded-border-content w-time ">
                    <p className="table-para-content ">09:00 AM - 09:45 AM</p>
                  </td>
                  <td className="rounded-border-content  w-topic">
                    <p className="table-para-content text-start">
                      Business in 2025 & Impact of Al
                    </p>
                  </td>
                  <td className="rounded-border-content w-speaker">
                    <p className="table-para-content text-start pt-3">
                      <span className="font-bold ">Aircel Sivasankaran</span>
                    </p>
                  </td>
                </tr>

                <tr>
                  <td className="rounded-border-content w-time ">
                    <p className="table-para-content ">09:45 AM - 10:15 AM</p>
                  </td>
                  <td className="rounded-border-content  w-topic">
                    <p className="table-para-content text-start">
                      Digital Deepak, Gopal, SRK Moderator
                    </p>
                  </td>
                  <td className="rounded-border-content w-speaker">
                    <p className="table-para-content text-start pt-3">
                      Panel Discussion
                    </p>
                  </td>
                </tr>
                <tr>
                  <td className="rounded-border-content w-time ">
                    <p className="table-para-content pt-3 ">
                      10:15 AM - 10:45 AM
                    </p>
                  </td>
                  <td className="rounded-border-content  w-topic">
                    <p className="table-para-content pt-3  text-start">
                      Agentic Al in Sales & Marketing
                    </p>
                  </td>
                  <td className="rounded-border-content w-speaker">
                    <p className="table-para-content  text-start pt-3">
                      <span className="font-bold ">Vishnu Hari</span>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td className="rounded-border-yellow w-time ">
                    <p className="table-yellow-content">10:45 AM - 11:00 AM</p>
                  </td>
                  <td className="rounded-border-yellow w-speaker" colSpan={2}>
                    <p className="table-yellow-content">BREAK</p>
                  </td>
                </tr>
                <tr>
                  <td className="rounded-border-content w-time ">
                    <p className="table-para-content ">11:00 AM - 11:30 AM</p>
                  </td>
                  <td className="rounded-border-content  w-topic">
                    <p className="table-para-content text-start">
                      Bulletproof Mindset for New Age Entrepreneurs
                    </p>
                  </td>
                  <td className="rounded-border-content w-speaker">
                    <p className="table-para-content text-start">
                      <span className="font-bold  text-start">
                        Dharaneetharan G D
                      </span>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td className="rounded-border-content w-time ">
                    <p className="table-para-content ">11:30 AM - 12:00 PM</p>
                  </td>
                  <td className="rounded-border-content  w-topic">
                    <p className="table-para-content  text-start">
                      Build your Best Funnel
                    </p>
                  </td>
                  <td className="rounded-border-content w-speaker">
                    <p className="table-para-content text-start">
                      FlexiFunnels
                    </p>
                  </td>
                </tr>
                <tr>
                  <td className="rounded-border-content w-time ">
                    <p className="table-para-content ">12:00 PM - 12:30 PM</p>
                  </td>
                  <td className="rounded-border-content  w-topic">
                    <p className="table-para-content  text-start">
                      Epaphra, Kenny, Prem - Content Mastery
                    </p>
                  </td>
                  <td className="rounded-border-content w-speaker">
                    <p className="table-para-content text-start">
                      Panel Discussion
                    </p>
                  </td>
                </tr>
                <tr>
                  <td className="rounded-border-content w-time ">
                    <p className="table-para-content ">12:30 PM - 01:15 PM</p>
                  </td>
                  <td className="rounded-border-content  w-topic">
                    <p className="table-para-content  text-start"></p>
                  </td>
                  <td className="rounded-border-content w-speaker">
                    <p className="table-para-content text-start">Dr Velumani</p>
                  </td>
                </tr>
                <tr>
                  <td className="rounded-border-yellow w-time ">
                    <p className="table-yellow-content">01:15 PM - 02:00 PM</p>
                  </td>
                  <td className="rounded-border-yellow w-speaker" colSpan={2}>
                    <p className="table-yellow-content">LUNCH</p>
                  </td>
                </tr>
                <tr>
                  <td className="rounded-border-content w-time ">
                    <p className="table-para-content ">02:00 PM - 02:30 PM</p>
                  </td>
                  <td className="rounded-border-content  w-topic">
                    <p className="table-para-content  text-start"></p>
                  </td>
                  <td className="rounded-border-content w-speaker">
                    <p className="table-para-content  text-start">
                      <span className="font-bold ">Surekha</span>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td className="rounded-border-content w-time ">
                    <p className="table-para-content ">02:30 PM - 03:00 PM</p>
                  </td>
                  <td className="rounded-border-content  w-topic">
                    <p className="table-para-content text-start"></p>
                  </td>
                  <td className="rounded-border-content w-speaker">
                    <p className="table-para-content text-start">
                      Sudharsanan Ganapathy
                    </p>
                  </td>
                </tr>
                <tr>
                  <td className="rounded-border-yellow w-time ">
                    <p className="table-yellow-content">03:00 PM - 03:15 PM</p>
                  </td>
                  <td className="rounded-border-yellow w-speaker" colSpan={2}>
                    <p className="table-yellow-content">BREAK</p>
                  </td>
                </tr>
                <tr>
                  <td className="rounded-border-content w-time ">
                    <p className="table-para-content ">03:15 PM - 03:45 PM</p>
                  </td>
                  <td className="rounded-border-content  w-topic">
                    <p className="table-para-content text-start"></p>
                  </td>
                  <td className="rounded-border-content w-speaker">
                    <p className="table-para-content text-start">
                      <span className="font-bold  text-start">
                        Akhila Rajeshwar
                      </span>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td className="rounded-border-content w-time ">
                    <p className="table-para-content ">03:45 PM - 04:15 PM</p>
                  </td>
                  <td className="rounded-border-content  w-topic">
                    <p className="table-para-content text-start"></p>
                  </td>
                  <td className="rounded-border-content w-speaker">
                    <p className="table-para-content text-start">
                      <span className="font-bold  text-start"></span>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td className="rounded-border-content w-time ">
                    <p className="table-para-content ">04:15 PM - 04:45 PM</p>
                  </td>
                  <td className="rounded-border-content  w-topic">
                    <p className="table-para-content text-start"></p>
                  </td>
                  <td className="rounded-border-content w-speaker">
                    <p className="table-para-content text-start">
                      <span className="font-bold  text-start">
                        Dharaneetharan G D
                      </span>
                    </p>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AgendaContent;
