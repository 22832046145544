import React from "react";
import cardicon from "../../assets/images/Awards/cardicon.webp";
import cardiconwhite from "../../assets/images/Awards/cardicon-white.webp";

const AwardsContent = () => {
  return (
    <div>
      <section className="awards-page">
        <div className="container pt-5">
          <div className="row justify-content-center">
            <div className="col-lg-10">
              <div className="row">
                <div className="col-lg-4 col-md-4 award-flex">
                  <div className="award-card">
                    <img
                      src={require("../../assets/images/Awards/card9.webp")}
                      className="card-img"
                      alt=""
                    />
                    <div className="cardpt">
                      BEST ENTREPRENEUR <br />
                      OF THE YEAR
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 award-flex">
                  <div className="award-card">
                    <img
                      src={require("../../assets/images/Awards/card1.webp")}
                      className="card-img"
                      alt=""
                    />
                    <div className="cardpt">
                      DISRUPTOR OF THE <br />
                      YEAR
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 award-flex">
                  <div className="award-card">
                    <img
                      src={require("../../assets/images/Awards/card2.webp")}
                      className="card-img"
                      alt=""
                    />
                    <div className="cardpt">
                      POSITIVE IMPACT <br />
                      CREATOR
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 award-flex">
                  <div className="award-card">
                    <img
                      src={require("../../assets/images/Awards/card12.webp")}
                      className="card-img"
                      alt=""
                    />
                    <div className="cardpt">
                      BEST FITNESS <br /> INFLUENCER
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 award-flex">
                  <div className="award-card">
                    <img
                      src={require("../../assets/images/Awards/card3.webp")}
                      className="card-img"
                      alt=""
                    />
                    <div className="cardpt">
                      BEST TRAVEL <br />
                      INFLUENCER
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 award-flex">
                  <div className="award-card">
                    <img
                      src={require("../../assets/images/Awards/card4.webp")}
                      className="card-img"
                      alt=""
                    />
                    <div className="cardpt">
                      BEST FOOD <br /> INFLUENCER
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 award-flex">
                  <div className="award-card">
                    <img
                      src={require("../../assets/images/Awards/card10.webp")}
                      className="card-img"
                      alt=""
                    />
                    <div className="cardpt">
                      MINDSET & PSYCHOLOGY <br /> INFLUENCER
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 award-flex">
                  <div className="award-card">
                    <img
                      src={require("../../assets/images/Awards/card5.webp")}
                      className="card-img"
                      alt=""
                    />
                    <div className="cardpt">
                      EMERGING INFLUENCER <br />
                      OF THE YEAR
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="pb-5">
          <div className="d-flex justify-content-center gap-5 items-center mb-4">
            <a href="https://forms.gle/nwDJRXw5UYGa6tHCA" target="_blank">
              <button className="btn award-btn">
                <div className="figure">
                  <img className="ticket-white" src={cardiconwhite} alt="" />
                  <img className="ticket-black" src={cardicon} alt="" />
                </div>
                <span>Nominate Yourself</span>
              </button>
            </a>
          </div>
        </div>
        <div className="d-flex award-best px-0 ">
          Best Digital Agency Awards
        </div>
        <div className="gradient-border"></div>
        <div className="container pt-5 mt-5">
          <div className="row justify-content-center pt-5">
            <div className="col-lg-10">
              <div className="row">
                <div className="col-lg-4 col-md-4 award-flex">
                  <div className="award-card">
                    <img
                      src={require("../../assets/images/Awards/card11.webp")}
                      className="card-img"
                      alt=""
                    />
                    <div className="cardpt">
                      BEST META <br /> CAMPAIGN
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 award-flex">
                  <div className="award-card">
                    <img
                      src={require("../../assets/images/Awards/card6.webp")}
                      className="card-img"
                      alt=""
                    />
                    <div className="cardpt">
                      BEST GOOGLE <br /> CAMPAIGN
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 award-flex">
                  <div className="award-card">
                    <img
                      src={require("../../assets/images/Awards/card7.webp")}
                      className="card-img"
                      alt=""
                    />
                    <div className="cardpt">
                      BEST DIGITAL AGENCY <br />
                      OF THE YEAR
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-center gap-5 items-center mb-3">
          <a href="https://forms.gle/nwDJRXw5UYGa6tHCA" target="_blank">
            <button className="btn award-btn">
              <div className="figure">
                <img className="ticket-white" src={cardiconwhite} alt="" />
                <img className="ticket-black" src={cardicon} alt="" />
              </div>
              <span>Nominate Yourself</span>
            </button>
          </a>
        </div>
      </section>
    </div>
  );
};

export default AwardsContent;
