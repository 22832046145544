import React, { useEffect, useState } from "react";
import Video from "../../assets/videos/banner-video.mp4";
import Location from "../../assets/images/location.webp";
import TicketWhite from "../../assets/images/ticket-white.webp";
import TicketBlack from "../../assets/images/ticket-black.webp";
import BannerLogo from "../../assets/images/banner-logo.webp";
import OwlCarousel from "react-owl-carousel3";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { useNavigate } from "react-router-dom";
import { scrollToSection } from "../../common/utills";
import TimerSection from "../../common/TimerSection";
import BannerTimer from "../../common/BannerTimer";
const HomeBanner = () => {
  const navigate = useNavigate();
  const ourLogos = [
    {
      img: require("../../assets/images/our-clients/c1.webp"),
    },
    {
      img: require("../../assets/images/our-clients/c2.webp"),
    },
    {
      img: require("../../assets/images/our-clients/c3.webp"),
    },
    {
      img: require("../../assets/images/our-clients/c4.webp"),
    },
    {
      img: require("../../assets/images/our-clients/c5.webp"),
    },
    {
      img: require("../../assets/images/our-clients/c6.webp"),
    },
    {
      img: require("../../assets/images/our-clients/c7.webp"),
    },
    {
      img: require("../../assets/images/our-clients/c8.webp"),
    },
    {
      img: require("../../assets/images/our-clients/c9.webp"),
    },
    {
      img: require("../../assets/images/our-clients/c10.webp"),
    },
  ];

  return (
    <>
      <div className="home-banner-section">
        <video autoPlay loop muted>
          <source src={Video} type="video/mp4" />
        </video>
        <div className="overlay"></div>
        <div className="home-video-content">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-11">
                <div className="text-center">
                  <img className="banner-logo" src={BannerLogo} alt=""></img>
                </div>
                <h1 className="banner-heading text-center mb-3">
                  “Getting Tamil Nadu ready for the Digital Future”{" "}
                </h1>
                <p className="banner-rounded-content">
                  <span>26 & 27 April 2025 &nbsp; | </span>{" "}
                  <img
                    className="location-img ms-2"
                    src={Location}
                    alt=""
                  ></img>
                  <span>Hilton, Chennai </span>
                </p>
                <BannerTimer />
                <div className="d-flex justify-content-center gap-5 items-center  ">
                  <div className="nav-btn-container">
                    <button
                      onClick={() => navigate("/Ticket")}
                      className="btn banner-btn"
                    >
                      <div className="figure">
                        <img
                          className="ticket-white"
                          src={TicketWhite}
                          alt=""
                        />
                        <img
                          className="ticket-black"
                          src={TicketBlack}
                          alt=""
                        />
                      </div>
                      <span>Buy Tickets</span>
                    </button>
                  </div>
                  <a
                    href="#/#Speakers"
                    onClick={(e) => {
                      e.preventDefault();
                      scrollToSection("Speakers");
                    }}
                  >
                    <button className="speaker-btn">Speakers</button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="md-scroll-section">
          <div className="container-fluid">
            <div className="scroll">
              <OwlCarousel
                className="owl-theme"
                loop={true}
                items={1}
                dots={false}
                nav={false}
                margin={0}
                center={true}
                autoplay={true}
                responsive={{
                  360: {
                    items: "2",
                  },
                  414: {
                    items: "2",
                  },
                  670: {
                    items: "3",
                  },
                  992: {
                    items: "5",
                  },
                  1200: {
                    items: "5",
                  },
                  1300: {
                    items: "5",
                  },
                }}
              >
                {ourLogos.map((item, index) => {
                  return (
                    <img
                      key={index}
                      className="scroll-banner-logos"
                      src={item.img}
                      alt=""
                    />
                  );
                })}
              </OwlCarousel>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-light pb-2 d-lg-none d-md-none d-block">
        <div className="container-fluid">
          <div className="scroll">
            <OwlCarousel
              className="owl-theme"
              loop={true}
              items={1}
              dots={false}
              nav={false}
              margin={0}
              center={true}
              autoplay={true}
              responsive={{
                360: {
                  items: "2",
                },
                414: {
                  items: "2",
                },
                670: {
                  items: "3",
                },
                992: {
                  items: "5",
                },
                1200: {
                  items: "5",
                },
                1300: {
                  items: "5",
                },
              }}
            >
              {ourLogos.map((item, index) => {
                return (
                  <img
                    key={index}
                    className="scroll-banner-logos"
                    src={item.img}
                    alt=""
                  />
                );
              })}
            </OwlCarousel>
          </div>
        </div>
      </div>
      <TimerSection />
    </>
  );
};

export default HomeBanner;
